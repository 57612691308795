import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'google-adsense',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnInit, AfterViewInit  {

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
     try {
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        console.error(e);
      }
    }, 2000);
    }

}
