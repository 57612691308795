import * as path from 'path';
//import {getCanvasImage, HorizontalImage, registerFont, UltimateTextToImage, VerticalImage} from "ultimate-text-to-image";
import { UltimateTextToImage} from "ultimate-text-to-image";
//const {UltimateTextToImage} = require("ultimate-text-to-image");
import { Component, OnInit,LOCALE_ID, Input, TemplateRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import * as process from "process";
const { createCanvas, loadImage  } = require('canvas')
const fs = require('fs')
import Text2Image from '@xg4/text2image';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as ClipboardJS from 'clipboard';
import { saveAs } from 'file-saver';
import counterUp from 'counterup2';
import { timer, Subscription } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'hindi-typing';
  userText: string = '';
  teluguText: string = '';
  bengaliText: string = '';
  marathiText: string = '';
  gujaratiText: string = '';
  tamilText: string = '';
  urduText: string ='';
  malayalamText: string ='';
  kannadaText: string ='';
  odiaText: string ='';


  err: boolean = false;
  wordCount: number = 0;
  teluguWordCount: number = 0;
  bengaliWordCount: number = 0;
  marathiWordCount: number = 0;
  gujaratiWordCount: number = 0;
  tamilWordCount: number = 0; 
  urduWordCount: number = 0;
  malayalamWordCount: number = 0;
  kannadaWordCount: number = 0;
  odiaWordCount: number = 0;
  modalRef: BsModalRef;
  fontLink: string = 'assets/Akshar Normal.ttf';
  fontName: string = 'Akshar Normal';

  playerModalTitle : string = 'How to learn our courses?';

  firstVideo: boolean = true;

  videoPlayerUrl : SafeResourceUrl;//  = 'https://www.youtube.com/watch?v=pvspuc5bits';
  //indicTypingUrl : string = 'https://www.youtube.com/watch?v=Fd3NIrE53LM';
  
  //@ViewChild('hindiInputText', { static: false }) userInput: ElementRef;
  //@ViewChild('teluguInputText', { static: false }) teluguUserInput: ElementRef;
  //@ViewChild('bengaliInputText', { static: false }) bengaliUserInput: ElementRef;

  //hindiTabHeading: string = 'Hindi Typing';
  //teluguTabHeading: string = 'Telugu Typing';
  //bengaliTabHeading: string = 'Bengali Typing';

  public activeTab: string = 'tab1';

  constructor(private modalService: BsModalService, private sanitizer: DomSanitizer) {
    setTheme('bs4'); // or 'bs4'
    window["process"] = process;

    this.videoPlayerUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=pvspuc5bits');
  }
  
  ngOnInit(): void {
    new ClipboardJS('.clipboard');

    var div_list = document.querySelectorAll( '.counter' );
    div_list.forEach(div => {

      counterUp( div, {
        duration: 2000,
        delay: 2,
    } );

    });
   
 
  }

  

 openModal(template: TemplateRef<any>, type: number) {
   if( 1 == type){
     this.firstVideo = true;
    this.playerModalTitle = 'How to learn our courses?';
    this.videoPlayerUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=pvspuc5bits');
   } else if( 2 == type){
    this.firstVideo = false;
    this.playerModalTitle = 'How to use Indic Typing?';
    this.videoPlayerUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=Fd3NIrE53LM');
   }

    //this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
 }

 
changeTab($event) {
  this.activeTab =  $event.id;
  if( 'tab1' == $event.id){
    this.fontLink = 'assets/Akshar Normal.ttf';
    this.fontName = 'Akshar Normal';
  } else  if( 'tab2' == $event.id){
    this.fontLink = 'assets/Gidugu.ttf';
    this.fontName = 'Gidugu';
  } else  if( 'tab3' == $event.id){
    this.fontLink = 'assets/LikhanNormal.ttf';
    this.fontName = 'Likhan Normal';
  } else  if( 'tab4' == $event.id){
    this.fontLink = 'assets/Shreelipi0714.TTF';
    this.fontName = 'Shreelipi 0714';
  } else  if( 'tab5' == $event.id){
    this.fontLink = 'assets/Guj-Avantika-Normal.TTF';
    this.fontName = 'Gujarati Avantika Normal';
  } else  if( 'tab6' == $event.id){
    this.fontLink = 'assets/VANAVIL-Avvaiyar-Regular.otf';
    this.fontName = 'VANAVIL-Avvaiyar-Regular';
  } else  if( 'tab7' == $event.id){
    this.fontLink = 'assets/NotoNastaliqUrdu-Regular.ttf';
    this.fontName = 'NotoNastaliqUrdu-Regular';
  } else  if( 'tab8' == $event.id){
    this.fontLink = 'assets/ML-TTAmbili Normal.ttf';
    this.fontName = 'ML-TTAmbili Normal';
  } else  if( 'tab9' == $event.id){
    this.fontLink = 'assets/BARAHA_Kannad.TTF';
    this.fontName = 'Baraha Kannada';
  } else  if( 'tab10' == $event.id){
    this.fontLink = 'assets/Sarala-Odia.ttf';
    this.fontName = 'Sarala Odia';
  }
}

  countWords(str: string): number {
    str = str.replace(/(^\s*)|(\s*$)/gi,"");
    str = str.replace(/[ ]{2,}/gi," ");
    str = str.replace(/\n /,"\n");
    return str.split(' ').length;
  }

  userTypes(text){
    this.wordCount = this.countWords(text.target.value);
    this.err = false;    
  }

  marathiUserTypes(text){
    this.marathiWordCount = this.countWords(text.target.value);
    this.err = false;    
  }

  gujaratiUserTypes(text){
    this.gujaratiWordCount = this.countWords(text.target.value);
    this.err = false;    
  }

  teluguUserTypes(text){
    this.teluguWordCount = this.countWords(text.target.value);
    this.err = false;
  }
  
  bengaliUserTypes(text){
    this.bengaliWordCount = this.countWords(text.target.value);
    this.err = false;
  }

  tamilUserTypes(text){
    this.tamilWordCount = this.countWords(text.target.value);
    this.err = false;
  }

  urduUserTypes(text){
    this.urduWordCount = this.countWords(text.target.value);
    this.err = false;
  }

  malayalamUserTypes(text){
    this.malayalamWordCount = this.countWords(text.target.value);
    this.err = false;
  }

  kannadaUserTypes(text){
    this.kannadaWordCount = this.countWords(text.target.value);
    this.err = false;
  }

  odiaUserTypes(text){
    this.odiaWordCount = this.countWords(text.target.value);
    this.err = false;
  }

  exportAsImage(){

    if(this.userText.trim().length > 0 ){
      this.err = false;
      const ti = new Text2Image({
        fontSize: 13,
        color: '#000000',
        fontFamily: 'arial',
        fontWeight: 'bold',
        type: 'image/png',
        quality: 1
      })

      /* get mask image
      Text2Image.createMask(imgUrl).then(image => {
        // set background image
        ti.setMask(image)
      })*/

        // or
      const url = ti.toDataURL({
        text: this.userText
        // some options
      })

      const img = new Image();
      img.src = url;

      //var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = url;
      link.download = "hindi-typing-export.png";
      link.click();
      URL.revokeObjectURL(url);
    } else {
      this.err = true;
    }
    //document.body.appendChild(img);
  }

  saveTextAsFile (){
    //const keyPress = new KeyboardEvent('keypress', { 'keyCode': 32 });
    //const ev = new KeyboardEvent('keydown',{'keyCode':32,'which':32});
    //let event = new KeyboardEvent('keyup',{'bubbles':true, 'code': '32', 'key': '32', 'isComposing': false, 'location': 0, 'repeat': false });
    /*const event = new KeyboardEvent("keydown", {
      keyCode: 32,
    } as KeyboardEventInit);
*/ 
    var data = '', fileName = '';
    if( 'tab1' == this.activeTab){
      //this.userInput.nativeElement.dispatchEvent(ev);
      data = this.userText;
      fileName = 'hindi-typing.txt';
    } else  if( 'tab2' == this.activeTab){
      //this.teluguUserInput.nativeElement.dispatchEvent(ev);
      data = this.teluguText;
      fileName = 'telugu-typing.txt';
    } else  if( 'tab3' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.bengaliText;
      fileName = 'bengali-typing.txt';
    } else  if( 'tab4' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.marathiText;
      fileName = 'marathi-typing.txt';
    } else  if( 'tab5' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.gujaratiText;
      fileName = 'gujarati-typing.txt';
    } else  if( 'tab6' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.tamilText;
      fileName = 'tamil-typing.txt';
    } else  if( 'tab7' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.urduText;
      fileName = 'urdu-typing.txt';
    } else  if( 'tab8' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.malayalamText;
      fileName = 'malayalam-typing.txt';
    } else  if( 'tab9' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.kannadaText;
      fileName = 'kannada-typing.txt';
    } else  if( 'tab10' == this.activeTab){
      //this.bengaliUserInput.nativeElement.dispatchEvent(ev);
      data = this.odiaText;
      fileName = 'odia-typing.txt';
    }

    const blob = new Blob([data], { type: 'plain/text' });
    //const url = window.URL.createObjectURL(blob);
    //window.open(url);
    saveAs.saveAs(blob, fileName);
}

  countChar(str) {  
    var count = {};
    str.split('').forEach(function(s) {
       count[s] ? count[s]++ : count[s] = 1;
    });
    return count;
  }

  exportTextToImage(){
    const width = 1200
    const height = 630

    const canvas = createCanvas(width, height)
    const context = canvas.getContext('2d')

    context.fillStyle = '#000'
    context.fillRect(0, 0, width, height)

    context.font = 'bold 70pt Menlo'
    context.textAlign = 'center'
    context.textBaseline = 'top'
    context.fillStyle = '#3574d4'

    const text = 'Hello, World!'

    const textWidth = context.measureText(text).width
    context.fillRect(600 - textWidth / 2 - 10, 170 - 5, textWidth + 20, 120)
    context.fillStyle = '#fff'
    context.fillText(text, 600, 170)

    context.fillStyle = '#fff'
    context.font = 'bold 30pt Menlo'
    context.fillText('flaviocopes.com', 600, 530)

    loadImage('assets/images/logo.jpg').then(image => {
      context.drawImage(image, 340, 515, 70, 70)
      const buffer = canvas.toBuffer('image/png')
      fs.writeFileSync('./test.png', buffer)
    });

  }

  /*async exportTextToImage(){
    //new UltimateTextToImage(`abc xyz 0123456789 零一二三四五六七八九`, {width: 150, fontFamily: "Arial, Sans"})
    //.render()
    //.toFile("image1.png");
    
    // const path = require("path");
    // const {getCanvasImage, HorizontalImage, registerFont, UltimateTextToImage, VerticalImage} = require("ultimate-text-to-image");

    // render the image
    const textToImage = new UltimateTextToImage("Ultimate Text To Image", {
        width: 400,
        maxWidth: 1000,
        maxHeight: 1000,
        fontFamily: "Arial",
        fontColor: "#00FF00",
        fontSize: 72,
        minFontSize: 10,
        lineHeight: 50,
        autoWrapLineHeightMultiplier: 1.2,
        margin: 20,
        marginBottom: 40,
        align: "center",
        valign: "middle",
        borderColor: 0xFF000099,
        borderSize: 2,
        backgroundColor: "0080FF33",
        underlineColor: "#00FFFF33",
        underlineSize: 2,
    });
    

    // properties
    const width = textToImage.width; // final canvas size
    const height = textToImage.height;  // final canvas size
    const renderedTime = textToImage.renderedTime; // rendering time of canvas
    const measuredParagraph = textToImage.measuredParagraph; // all the details of the texts in size
    const canvas = textToImage.canvas; // the node-canvas
    const hasRendered = textToImage.hasRendered; // a flag to indicate if render() has run

    // render again (this will create a new canvas)
    const options = textToImage.options.fontFamily = "Comic Sans MS";
    const buffer = textToImage.render().toBuffer("image/jpeg");
    
    
    textToImage.render().toFile("hindi-typing-file.png"); 


  }*/

  
}

