import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy{

  
  countDown: Subscription;
  counter = 86400;
  tick = 1000;
  heading2 : boolean = false;
  navbarOpen = false;

  constructor() { }

  ngOnInit(): void {
    this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
  }

  ngOnDestroy(){
    this.countDown=null;
  }

  hideAd(){
    this.heading2 = true;
    
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  
}
