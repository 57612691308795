<app-header></app-header> 
    
<div class="row video-section" >

  <div class="card-group col-md-12 text-center p-0"> 
    <!-- <p class="text-center font-weight-bold col-12">Videos</p>        -->    
    <div class="card">
      <div class="card-body" style="padding: 1rem;">
        <div class="row">
        <h4 class="card-title ml-5">Learn Computer Courses @ Home With Certificate </h4>
         <button type="button" class="btn btn-secondary ml-4"><a style="color: #fff;"href="https://skillcourse.in/" target="_blank">Click Here</a></button>  
        </div>
      </div>
    </div>
    
    <div class="card">
      <div class="card-body" style="padding: 1rem;">
        <div class="row">
          <h4 class="card-title ml-5">How to use Indic Typing?</h4>
          <button type="button" class="btn btn-secondary ml-5" (click)="openModal(videoplayer, 2)">Watch Video </button>  
          </div>
      </div>
    </div>
  </div>
  <!--div class="col-md-1"></div-->
  </div> 


        <div class="row main-div">

            <div class="col-md-7 trans-area">
                <tabset>

                  <tab id="tab1" (selectTab)="changeTab($event)">
                      <ng-template tabHeading> 
                        <b>Hindi</b>
                      </ng-template>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                      </svg>
                      <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Hindi word. </i><br>
                      <i class="bi bi-info text-danger small-text"> अंग्रेजी में एक शब्द टाइप करें और हिंदी शब्द में बदलने के लिए स्पेसबार (स्पेस जोड़ें) दबाएं। </i><br>
                      <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                      <p class="info-text small-text">- कॉपी और पेस्ट न करें, शब्द दर शब्द टाइप करें।</p--> 
                      
                      <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Hindi) </p>
                      <!--p class="info-text small-text">- हिंदी में टाइप करें (अंग्रेजी और हिंदी के बीच टॉगल करने के लिए <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span> दबाएं) </p-->

                      <div class="row">
                        <div class="col-12" style="position:relative">
                          <textarea #hindiInputText id="transliterateTextarea" class="col-12" [(ngModel)]="userText" 
                            (keyup)="userTypes($event)" style="height:200px">
                          </textarea>
                          <button class="btn btn-secondary clipboard" data-clipboard-target="#transliterateTextarea" style="left: 86% !important;" >कॉपी करें
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                            </svg>
                        </button>
                     </div>   
                    </div>                          
                      <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                      <div style="font-weight: 700; margin-top:-4%">Word Count / शब्द गणना: <span>{{wordCount}}</span></div>

                      <div class="row">
                        <div class="col-12 btm-btns " >
                          <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                          <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Hindi Font </button>  
                        </div> 
                      </div>
                  </tab>

                  <tab id="tab4" (selectTab)="changeTab($event)">
                    <ng-template tabHeading> 
                      <b>Marathi</b>
                    </ng-template>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                    <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Marathi word. </i><br>
                    <i class="bi bi-info text-danger small-text"> इंग्रजीमध्ये एक शब्द टाइप करा आणि मराठी शब्दात रूपांतरित करण्यासाठी स्पेसबार (स्पेस जोडा) दाबा.</i><br>
                    <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                    <p class="info-text small-text">- कॉपी आणि पेस्ट करू नका, शब्दानुसार शब्द टाइप करा</p--> 
                    
                    <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Marathi) </p>
                    <!--p class="info-text small-text">- इंग्रजीमध्ये टाइप करा (इंग्रजी आणि मराठीमध्ये टॉगल करण्यासाठी <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span> दाबा)</p-->

                    <div class="row">
                      <div class="col-12" style="position:relative">
                        <textarea #marathiInputText id="marathiTransliterateTextarea" class="col-12" [(ngModel)]="marathiText" 
                          (keyup)="marathiUserTypes($event)" style="height:200px">
                        </textarea>
                        <button class="btn btn-secondary clipboard" data-clipboard-target="#marathiTransliterateTextarea" style="left: 85% !important;">कॉपी करा
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                          </svg>
                      </button>
                   </div>   
                  </div>                          
                    <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                    <div style="font-weight: 700; margin-top:-4%">Word Count / शब्द संख्या: <span>{{marathiWordCount}}</span></div>

                    <div class="row">
                      <div class="col-12 btm-btns" >
                        <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                        <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Marathi Font </button>  
                      </div> 
                    </div>
                </tab>


                <tab id="tab5" (selectTab)="changeTab($event)">
                  <ng-template tabHeading> 
                    <b>Gujarati</b>
                  </ng-template>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                  </svg>
                  <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Gujarati word. </i><br>
                  <i class="bi bi-info text-danger small-text"> અંગ્રેજીમાં એક શબ્દ લખો અને મરાઠી શબ્દમાં કન્વર્ટ કરવા માટે સ્પેસબાર (સ્પેસ ઉમેરો) દબાવો.</i><br>
                  <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                  <p class="info-text small-text">- કૉપિ અને પેસ્ટ કરી શકો છો, શબ્દ પ્રમાણે શબ્દ લખો</p--> 
                  
                  <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Gujarati) </p>
                  <!--p class="info-text small-text">- અંગ્રેજીમાં ટાઈપ કરો (અંગ્રેજી અને ગુજરાતી વચ્ચે ટૉગલ કરવા માટે <span style="color:darkgreen; font-weight: 700;">Ctrl+q</span> દબાવો) </p-->

                  <div class="row">
                    <div class="col-12" style="position:relative">
                      <textarea #gujaratiInputText id="gujaratiTransliterateTextarea" class="col-12" [(ngModel)]="gujaratiText" 
                        (keyup)="gujaratiUserTypes($event)" style="height:200px">
                      </textarea>
                      <button class="btn btn-secondary clipboard" data-clipboard-target="#gujaratiTransliterateTextarea" style="left: 86% !important;">નકલ કરો
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                    </button>
                 </div>   
                </div>                          
                  <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                  <div style="font-weight: 700; margin-top:-4%">Word Count / શબ્દગણના: <span>{{gujaratiWordCount}}</span></div>

                  <div class="row">
                    <div class="col-12 btm-btns" >
                      <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                      <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Gujarati Font </button>  
                    </div> 
                  </div>
              </tab>

                  <tab id="tab2" (selectTab)="changeTab($event)">
                    <ng-template tabHeading class=""> 
                      <b>Telugu</b>
                    </ng-template>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                    <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Telugu word. </i><br>
                    <i class="bi bi-info text-danger small-text"> ఒక పదాన్ని ఆంగ్లంలో టైప్ చేసి, తెలుగు పదంగా మార్చడానికి స్పేస్‌బార్ (స్పేస్ జోడించు) నొక్కండి. </i><br>
                    <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                    <p class="info-text small-text">- కాపీ చేసి పేస్ట్ చేయవద్దు, పదం వారీగా టైప్ చేయండి.</p-->
                    
                    <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Telugu) </p>
                    <!--p class="info-text small-text">- ఆంగ్లంలో టైప్ చేయండి (ప్రెస్ <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>మధ్య టోగుల్ చేయడానికి English మరియు Telugu)</p-->
                    
                    <div class="row">
                      <div class="col-12" style="position:relative">
                        <textarea #teluguInputText id="teluguTransliterateTextarea" class="col-12" [(ngModel)]="teluguText" (keyup)="teluguUserTypes($event)" style="height:200px"></textarea><br>
                        <button class="btn btn-secondary clipboard" data-clipboard-target="#teluguTransliterateTextarea" style="left: 89% !important;">కాపీ
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                          </svg>
                        </button>
                      </div>    
                    </div>                   
                    <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                    <div style="font-weight: 700; margin-top:-4%">Word Count / పదాల లెక్క: <span>{{teluguWordCount}}</span></div>
                    
                    <div class="col-12 btm-btns" >
                      <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()"><i class="bi bi-info"></i>Export As File</button>                    
                      <button type="button" #export style="margin-left:15%;" class="btn btn-secondary pull-right col-3" (click)="openModal(template, -1)">Telugu Font </button>                                          
                    </div>

                </tab> 


                <tab id="tab3" (selectTab)="changeTab($event)">
                  <ng-template tabHeading class=""> 
                    <b>Bengali</b>
                  </ng-template>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                  </svg>
                  <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Bengali word. </i><br>
                  <i class="bi bi-info text-danger small-text"> ইংরেজিতে একটি শব্দ টাইপ করুন এবং বেগালি শব্দে রূপান্তর করতে স্পেসবার (স্পেস যোগ করুন) টিপুন।. </i><br>
                  <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                  <p class="info-text small-text">- কপি পেস্ট করবেন না, শব্দ করে টাইপ করুন.</p-->
                  
                  <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Bengali) </p>
                  <!--p class="info-text small-text">- ইংরেজীতে লিখুন (প্রেস <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>মধ্যে টগল করতে English এবং Bengali)</p-->

                  <div class="row">
                    <div class="col-12" style="position:relative">
                      <textarea #bengaliInputText id="bengaliTransliterateTextarea" class="col-12" [(ngModel)]="bengaliText" (keyup)="bengaliUserTypes($event)" style="height:200px"></textarea><br>
                      <button class="btn btn-secondary clipboard" data-clipboard-target="#bengaliTransliterateTextarea" style="left: 85% !important;">অনুলিপি
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                      </button>
                    </div>    
                  </div>
                  <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                  <div style="font-weight: 700; margin-top:-4%">Word Count / শব্দ গণনা: <span>{{bengaliWordCount}}</span></div>
                  
                  
                  <div class="col-12 btm-btns" style="">
                    <button type="button" #export  class="btn btn-success col-3" (click)="saveTextAsFile()"><i class="bi bi-info"></i>Export As File</button>                    
                    <button type="button" #export style="margin-left:15%;" class="btn btn-secondary pull-right col-3" (click)="openModal(template, -1)">Bengali Font </button>                                        
                  </div>

            
              </tab>
              
                <tab id="tab6" (selectTab)="changeTab($event)">
                  <ng-template tabHeading class=""> 
                    <b>Tamil</b>
                  </ng-template>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                  </svg>
                  <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Tamil word. </i><br>
                  <i class="bi bi-info text-danger small-text"> ஆங்கிலத்தில் ஒரு வார்த்தையைத் தட்டச்சு செய்து, தமிழ் வார்த்தையாக மாற்ற ஸ்பேஸ்பாரை அழுத்தவும் (இடத்தைச் சேர்க்கவும்). </i><br>
                  <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                  <p class="info-text small-text">- কপি পেস্ট করবেন না, শব্দ করে টাইপ করুন.</p-->
                  
                  <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Tamil) </p>
                  <!--p class="info-text small-text">- ইংরেজীতে লিখুন (প্রেস <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>মধ্যে টগল করতে English এবং Bengali)</p-->

                  <div class="row">
                    <div class="col-12" style="position:relative">
                      <textarea #tamilInputText id="tamilTransliterateTextarea" class="col-12" [(ngModel)]="tamilText" (keyup)="tamilUserTypes($event)" style="height:200px"></textarea><br>
                      <button class="btn btn-secondary clipboard" data-clipboard-target="#tamilTransliterateTextarea" style="left: 77%;" >நகலெடுக்கவும்
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                      </button>
                    </div>    
                  </div>
                  <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                  <div style="font-weight: 700; margin-top:-4%">Word Count / சொல் எண்ணிக்கை: <span>{{tamilWordCount}}</span></div>
                  
                  
                  <div class="col-12 btm-btns" style="">
                    <button type="button" #export  class="btn btn-success col-3" (click)="saveTextAsFile()"><i class="bi bi-info"></i>Export As File</button>                    
                    <button type="button" #export style="margin-left:15%;" class="btn btn-secondary pull-right col-3" (click)="openModal(template, -1)">Tamil Font </button>                                        
                  </div>

            
              </tab>

              <tab id="tab7" (selectTab)="changeTab($event)">
                <ng-template tabHeading> 
                  <b>Urdu</b>
                </ng-template>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Urdu word. </i><br>
                <i class="bi bi-info text-danger small-text"> انگریزی میں ایک لفظ ٹائپ کریں اور ہندی لفظ میں تبدیل کرنے کے لیے اسپیس بار دبائیں (اسپیس شامل کریں)۔ </i><br>
                <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                <p class="info-text small-text">- कॉपी और पेस्ट न करें, शब्द दर शब्द टाइप करें।</p--> 
                
                <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Urdu) </p>
                <!--p class="info-text small-text">- हिंदी में टाइप करें (अंग्रेजी और हिंदी के बीच टॉगल करने के लिए <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span> दबाएं) </p-->

                <div class="row">
                  <div class="col-12" style="position:relative">
                    <textarea #urduInputText id="urduTransliterateTextarea" class="col-12" [(ngModel)]="urduText" 
                      (keyup)="urduUserTypes($event)" style="height:200px">
                    </textarea>
                    <button class="btn btn-secondary clipboard" data-clipboard-target="#urduTransliterateTextarea" style="left: 81% !important;" >اسے کاپی کریں
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                      </svg>
                  </button>
               </div>   
              </div>   
                                     
                <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                <div style="font-weight: 700; margin-top:-4%"><span>{{urduWordCount}}</span>: Word Count / الفاظ کی گنتی </div>

                <div class="row">
                  <div class="col-12 btm-btns " >
                    <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                    <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Urdu Font </button>  
                  </div> 
                </div>
            </tab>
            

            <tab id="tab8" (selectTab)="changeTab($event)">
              <ng-template tabHeading> 
                <b>Malayalam</b>
              </ng-template>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Malayalam word. </i><br>
              <i class="bi bi-info text-danger small-text"> ഇംഗ്ലീഷിൽ ഒരു വാക്ക് ടൈപ്പുചെയ്ത് മലയാളം പദത്തിലേക്ക് പരിവർത്തനം ചെയ്യാൻ സ്പേസ്ബാർ അമർത്തുക (സ്പെയ്സ് ചേർക്കുക). </i><br>
              <!--p class="info-text">- Do not copy and paste, type word by word.</p>
              <p class="info-text small-text">- कॉपी और पेस्ट न करें, शब्द दर शब्द टाइप करें।</p--> 
              
              <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Malayalam) </p>
              <!--p class="info-text small-text">- हिंदी में टाइप करें (अंग्रेजी और हिंदी के बीच टॉगल करने के लिए <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span> दबाएं) </p-->

              <div class="row">
                <div class="col-12" style="position:relative">
                  <textarea #malayalamInputText id="malayalamTransliterateTextarea" class="col-12" [(ngModel)]="malayalamText" 
                    (keyup)="malayalamUserTypes($event)" style="height:200px">
                  </textarea>
                  <button class="btn btn-secondary clipboard" data-clipboard-target="#malayalamTransliterateTextarea" > പകർത്തുക
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                    </svg>
                </button>
             </div>   
            </div>                          
              <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
              <div style="font-weight: 700; margin-top:-4%">Word Count / വാക്കുകളുടെ എണ്ണം : <span>{{malayalamWordCount}}</span></div>

              <div class="row">
                <div class="col-12 btm-btns " >
                  <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                  <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Malayalam Font </button>  
                </div> 
              </div>
          </tab>

              <tab id="tab9" (selectTab)="changeTab($event)">
                <ng-template tabHeading> 
                  <b>Kannada</b>
                </ng-template>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Kannada word. </i><br>
                <i class="bi bi-info text-danger small-text"> ಇಂಗ್ಲಿಷ್‌ನಲ್ಲಿ ಪದವನ್ನು ಟೈಪ್ ಮಾಡಿ ಮತ್ತು ಕನ್ನಡ ಪದಕ್ಕೆ ಪರಿವರ್ತಿಸಲು ಸ್ಪೇಸ್‌ಬಾರ್ (ಸ್ಪೇಸ್ ಸೇರಿಸಿ) ಒತ್ತಿರಿ. </i><br>
                <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                <p class="info-text small-text">- कॉपी और पेस्ट न करें, शब्द दर शब्द टाइप करें।</p--> 
                
                <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Kannada) </p>
                <!--p class="info-text small-text">- हिंदी में टाइप करें (अंग्रेजी और हिंदी के बीच टॉगल करने के लिए <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span> दबाएं) </p-->

                <div class="row">
                  <div class="col-12" style="position:relative">
                    <textarea #kannadaInputText id="kannadaTransliterateTextarea" class="col-12" [(ngModel)]="kannadaText" 
                      (keyup)="kannadaUserTypes($event)" style="height:200px">
                    </textarea>
                    <button class="btn btn-secondary clipboard" data-clipboard-target="#kannadaTransliterateTextarea" style="left: 79% !important;"> ಅದನ್ನು ನಕಲಿಸಿ
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                      </svg>
                  </button>
              </div>   
              </div>                          
                <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                <div style="font-weight: 700; margin-top:-4%">Word Count / ಪದಗಳ ಎಣಿಕೆ : <span>{{kannadaWordCount}}</span></div>

                <div class="row">
                  <div class="col-12 btm-btns " >
                    <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                    <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Kannada Font </button>  
                  </div> 
                </div>
            </tab>


              <tab id="tab10" (selectTab)="changeTab($event)">
                <ng-template tabHeading> 
                  <b>Odia</b>
                </ng-template>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <i class="bi bi-info text-danger"> Type a word in English and press spacebar (add space) to convert into Odia word. </i><br>
                <i class="bi bi-info text-danger small-text"> ଇଂରାଜୀରେ ଏକ ଶବ୍ଦ ଟାଇପ୍ କରନ୍ତୁ ଏବଂ ଓଡିଆ ଶବ୍ଦରେ ରୂପାନ୍ତର କରିବାକୁ ସ୍ପେସ୍ ବାର୍ (ସ୍ପେସ୍ ଯୋଡନ୍ତୁ) ଦବାନ୍ତୁ | </i><br>
                <!--p class="info-text">- Do not copy and paste, type word by word.</p>
                <p class="info-text small-text">- कॉपी और पेस्ट न करें, शब्द दर शब्द टाइप करें।</p--> 
                
                <p class="info-text">- Type in English (Press <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span>to toggle between English and Odia) </p>
                <!--p class="info-text small-text">- हिंदी में टाइप करें (अंग्रेजी और हिंदी के बीच टॉगल करने के लिए <span style="color:darkgreen; font-weight: 700;"> Ctrl+q </span> दबाएं) </p-->

                <div class="row">
                  <div class="col-12" style="position:relative">
                    <textarea #odiaInputText id="odiaTransliterateTextarea" class="col-12" [(ngModel)]="odiaText" 
                      (keyup)="odiaUserTypes($event)" style="height:200px">
                    </textarea>
                    <button class="btn btn-secondary clipboard" data-clipboard-target="#odiaTransliterateTextarea" > ଏହାକୁ କପି କରନ୍ତୁ |
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="font-style: bold; font-weight: 900;" class="bi bi-clipboard" viewBox="0 0 16 16">
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                      </svg>
                  </button>
                    
              </div>   
              </div>                          
                <div id="errorDiv" *ngIf="err" style="padding:5px" >Please enter text.</div>
                <div style="font-weight: 700; margin-top:-4%">Word Count / ଶବ୍ଦ ଗଣନା |: <span>{{odiaWordCount}}</span></div>

                <div class="row">
                  <div class="col-12 btm-btns " >
                    <button type="button" #export class="btn btn-success col-3" (click)="saveTextAsFile()">Export As File</button>
                    <button type="button" #export class="btn btn-secondary pull-right col-3" style="margin-left:15%;"  (click)="openModal(template, -1)">Odia Font </button>  
                  </div> 
                </div>
            </tab>

                </tabset>
            </div>

            <div class="col-md-5">
              <a href="https://play.google.com/store/apps/details?id=co.thor.qensx" target="_blank" ><img src='assets/images/ad-banner1.jpg?${Date.now()}' class="img-fluid" alt="Responsive image"/></a>
              <div class="p-2"></div>
              <a href="https://play.google.com/store/apps/details?id=co.thor.qensx" target="_blank" ><img src='assets/images/ad-banner2.jpg?${Date.now()}' class="img-fluid" alt="Responsive image"/></a>
            </div>

            <!--div class="col-md-3"></div-->
        </div>

        <div class="row about-div">
          <div class="col-md-12">
            <h4 class="blog-title text-center"> About Online INDIC TYPING Software </h4>
            <p class="pl-5 pr-5 ">Indic Typing is Online typing Software that helps you to convert English to Hindi, English to Marathi, and other top 10 Indian regional languages. It’s one of the simplest and easiest online typing tool. now you don’t need to remember any more typing words.
              We provide you an <b>Indic Typing is FREE online typing tool (software)</b> that help you to convert English to Hindi, by using the Google transliteration typing service. This Indic Typing tool provides fast and accurate typing - making it easy to type <b>English to Hindi, English to Marathi, English to Gujrati, English to Bangali, English to Telugu, English to Tamil, English to Urdu, English to Malayalam, English to Kannada, English to Odia</b>.</p>
            <p class="pl-5 pr-5 ">To use Indic Typing software you just have to type a word in English and press a space bar key, the word will be transliterated into Selected Language. If you are not getting your proper word then press backspace to get options and select words from those options.
            This online typing software is specially designed to save your time to transliterate English words into 10 different Indian languages.  English to Hindi or English to any other language transliteration is very quick and allow you to type unlimited character even we have given a download text file option. When you finish your typing job then click on the Export as file button to download this content into a text file.
            Our Indic Typing Fast and Easy Language Typing software allow you to type fast it’s really simple and easy to use, now you don’t need to remember any more typing words or any fonts character position on the keyboard. If you are good with English typing that is enough.</p>
            <p class="pl-5 pr-5 ">This Online typing software tools make available you for completely free by <b>Satish Dhawale Founder of Learn More Pro – E-Learning company also 5 silver and 1 Gold Button Award Achiever for their YouTube Channels</b> to know more about Satish sir and his YouTube Channel Scroll Down and check links. Thanks for using Online Indic Typing software (Typing Made Easy).  To learn more from Satish sir visit Website: <a style="color: black;" href="https://learnmorepro.com" target="_blank"><u>https://learnmorepro.com</u></a></p>
          </div>
        </div>

        <div class="row course-section" >

            <div class="col-1"></div>
            <div class="card-group col-10 text-center"> 
              <h3 class="text-center font-weight-bold col-12 main-section-heading">Popular Courses For You</h3>           
              <div class="card">
                <div class="card-body">
                <img src="assets/images/excel.png"  style=" width: 8vw;" class="card-img-top" alt="MS Excel" width="80">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Excel Mastery</h5>
                  <a href="https://learnmorepro.com/courses/excel-mastery-course/" class="btn btn-info" role="button">
                      <span>Enroll Now</span>
                    </a>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                <img src="assets/images/hardware.png"  style=" width: 8vw;" class="card-img-top" alt="Computer Hardware">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Computer Hardware</h5>
                  <a href="https://learnmorepro.com/courses/computer-hardware-course/" class="btn btn-info" role="button">
                        <span>Enroll Now</span>
                    </a>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                <img src="assets/images/microsoft-publisher.png"  style=" width: 8vw;" class="card-img-top" alt="microsoft-publisher">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Microsoft Publisher</h5>
                  <a href="https://learnmorepro.com/courses/microsoft-publisher/" class="btn btn-info" role="button">
                      <span>Enroll Free</span>
                    </a>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                <img src="assets/images/powerpoint.png"  style=" width: 8vw;" class="card-img-top" alt="MS POwerpoint">
                </div>
                <div class="card-body">
                  <h5 class="card-title">PowerPoint</h5>
                  <a href="https://learnmorepro.com/courses/powerpoint-full-course/" class="btn btn-info" role="button">
                        <span>Enroll Free</span>
                      </a>
                </div>
              </div>

              <div class="p-2 col-12"></div>

              <div class="card">
                <div class="card-body">
                <img src="assets/images/cdr-file-format-symbol.png"  style=" width: 8vw;" class="card-img-top" alt="CorelDRAW" width="80">
                </div>
                <div class="card-body">
                  <h5 class="card-title">CorelDRAW</h5>
                  <a href="https://learnmorepro.com/courses/coreldraw-full-course/" class="btn btn-info" role="button">
                      <span>Enroll Now</span>
                    </a>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                <img src="assets/images/excel-1.png"  style=" width: 8vw;" class="card-img-top" alt="Excel MIS">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Excel MIS</h5>
                  <a href="https://learnmorepro.com/courses/excel-mis-course/" class="btn btn-info" role="button">
                        <span>Enroll Now</span>
                    </a>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                <img src="assets/images/photoshop.png"  style=" width: 8vw;" class="card-img-top" alt="Photoshop">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Photoshop</h5>
                  <a href="https://learnmorepro.com/courses/photoshop-full-course/" class="btn btn-info" role="button">
                      <span>Enroll Free</span>
                    </a>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                <img src="assets/images/sheets.png"  style=" width: 8vw;" class="card-img-top" alt="Google Sheets">
                </div>
                <div class="card-body">
                  <h5 class="card-title">Google Sheets</h5>
                  <a href="https://learnmorepro.com/courses/google-sheet-full-course/" class="btn btn-info" role="button">
                        <span>Enroll Free</span>
                      </a>
                </div>
              </div>
              
              <p class="text-center font-weight-bold col-12">
              <a href="https://learnmorepro.com/all-courses/" class="btn btn-success" role="button">
                    <span>
                <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i>			</span>
                    <span class="elementor-button-text">View All Courses</span>
                  </a>
              </p>    

            </div>
            <div class="col-1"></div>
        </div>  
       

        <div class="row course-section" >

          <div class="col-1"></div>
          <div class="card-group col-10 text-center"> 
            <h3 class="text-center font-weight-bold col-12 main-section-heading">Free videos for you on YouTube</h3>           
            <div class="card">
              <div class="card-body">
              <img src="assets/images/learn-more.png"  style=" width: 8vw;" class="card-img-top" alt="MS Excel" width="80">
              </div>
              <div class="card-body">
                <a href="https://skillcourse.in/" class="btn btn-secondary" role="button">
                    <span>Learn More</span>
                  </a>
                  <div class="counter">13,00,000+</div>
                  <h6 class="card-title">Subscribers</h6>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
              <img src="assets/images/Excel-Tutorials.png"  style=" width: 8vw;" class="card-img-top" alt="Computer Hardware">
              </div>
              <div class="card-body">
                <a href="https://www.youtube.com/channel/UCpZxF2O5dgn0s-du2AnYGOA/videos" class="btn btn-secondary" role="button">
                      <span>Excel Tutorials</span>
                  </a>
                  <div class="counter">60000+</div>
                  <h6 class="card-title">Subscribers</h6>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
              <img src="assets/images/tally.jpg"  style=" width: 8vw;" class="card-img-top" alt="microsoft-publisher">
              </div>
              <div class="card-body">
                <a href="https://www.youtube.com/c/TallyTutorial/videos" class="btn btn-secondary" role="button">
                    <span>Tally Tutorials</span>
                  </a>
                  <div class="counter">1,50,000+</div>
                  <h6 class="card-title">Subscribers</h6>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
              <img src="assets/images/complete-technology.jpg"  style=" width: 8vw;" class="card-img-top" alt="MS POwerpoint">
              </div>
              <div class="card-body">                
                <a href="https://www.youtube.com/c/CompleteTechnology/videos" class="btn btn-secondary" role="button">
                      <span>Complete Tutorials</span>
                    </a>
                  <div class="counter">2,00,000+</div>
                <h6 class="card-title">Subscribers</h6>
              </div>
            </div>
        </div>    
        <div class="col-1"></div>
      </div>  

<app-footer></app-footer>


<ng-template #template>
  <div class="modal-header">
     <h4 class="modal-title pull-left">How to install Font in your computer?</h4>
     <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
     </button>
  </div>
  <div class="modal-body">
    <a class="button btn btn-success" download="{{fontName}}.ttf" href="{{fontLink}}"><i class="pi pi-arrow-down" style="font-size: 1rem"></i> Download {{fontName}}</a>        
    <br><br>
    <div class="col-12 tab2" style="padding: 0px">

      <b>How to install {{fontName}} in your computer?</b>
      
                  <br><b>For Windows 7 / Vista users:</b><br>
                  - Right-click the <b>{{fontName}}</b> font file(s) and choose &quot;Install&quot;.<br>
                  <br>
                  <b>For users of the previous Windows versions:</b><br>
                  - Copy <b>{{fontName}}</b> font &amp; pest into a default Windows font folder (usually 
                  C:\WINDOWS\FONTS or C:\WINNT\FONTS)<br>
                  <br>
                  <b>For Mac users:</b><br>
                  Mac OS X 10.3 or above (including the FontBook)<br>
                  - Double-click <b>{{fontName}}</b> font file and hit &quot;Install font&quot; 
                  button at <br>
                  the bottom of the preview.<br>
                  <br>
                  <b>Mac OS X</b><br>
                  - Either copy the <b>{{fontName}}</b> font file(s) to /Library/Fonts (for all 
                  users), or to /Users/Your_username/Library/Fonts (for you only).<br>
                  <br>
                  <b>Mac OS 9 or earlier</b><br>
                  - You have to convert the <b>{{fontName}}</b> font file(s) you have downloaded. Drag 
                  the font suitcases into the System folder. The system will propose you to add 
                  them to the Fonts folder.<br>
                  <br>
                  <b>For Linux users:</b><br>
                  - Copy the <b>{{fontName}}</b> font file(s) to /USR/SHARE/FONTS
                </div>
  </div>
  <div class="modal-footer">
     <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>

  
<ng-template #videoplayer>
  <div class="modal-header ">
     <h4 class="modal-title pull-left" *ngIf="firstVideo">Which computer course you should do?</h4>
     <h4 class="modal-title pull-left" *ngIf="!firstVideo">How to use Indic Typing?</h4>

     <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
     </button>
  </div>
  <div class="modal-body bd-example-modal-lg">
    <div class="col-md-12 youtube" style="padding: 0px">
        <!--video [vgMedia]="media" #media id="singleVideo" type="video/mp4" controls height="460px" width="770px">
            <source src="{{videoPlayerUrl}}" type="video/mp4">
        </video-->
        <!--video data-yt2html5="https://www.youtube.com/watch?v=ScMzIvxBSi4"></video-->
        <!--video controls="controls" class="video-stream" x-webkit-airplay="allow" data-youtube-id="N9oxmRT2YWw" src="https://www.youtube.com/watch?v=ScMzIvxBSi4"></video-->
        <!--iframe width="770" height="400" [src]='videoPlayerUrl' frameborder="0" allowfullscreen> </iframe-->
        <iframe *ngIf="firstVideo" width="770" height="400" src="https://www.youtube.com/embed/yj9V0mjMeAk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe *ngIf="!firstVideo" width="770" height="400" src="https://www.youtube.com/embed/eIbuQhRn6eY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
  <div class="modal-footer">
     <button type="button" class="btn btn-default btn-secondary" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>