<div class="row">
    <div class="col-12 promotion-section">
      <div class="col-12 text-center"><img src="assets/images/Satish-Dhawale-learn-more-pro.jpeg" style="margin-top:-9%" class="align-self-center mr-3" alt="Satish Dhawale Sir"></div>
      <div class="row">
      <div class="col-12 text-center"><h3 class="mt-0">Man Behind Indic Typing</h3></div></div>
      <div class="col-12">
        <p class="col-12">मुझे कंप्यूटर शिक्षण के क्षेत्र में 14 साल का अनुभव है, मैं ऑफलाइन कंप्युटर संस्थान के क्षेत्र में कंप्युटर का शिक्षक रहा हु। जहाँ मैंने 14 वर्षों के अंतराल में 40000 से अधिक छात्रों को कंप्यूटर शिक्षा दी। उसके बाद मैंने अपना YouTube चैनल Learn More शुरू किया, जहाँ लोगों ने मेरी शिक्षण शैली को पसंद किया और उनके प्यार से मैं सफल रहा हूँ। वर्तमान मे मैं 7 यूट्यूब चैनल को मैनेज कर रहा हु। मैंने अपनी ब्लॉगिंग वेबसाइट भी शुरू की है जहाँ आप अभी हैं। यहाँ मैं कंप्यूटर, MS Office, Tally, MSCIT, Photoshop आदि के बारे में सभी महत्वपूर्ण और ज्ञानवर्धक जानकारी प्रदान करता हूँ। 
          हमारे शैक्षिक मंच <a href="www.learnmorepro.com" target="_blank">www.learnmorepro.com</a> जहां हम सस्ते दर पर कंप्यूटर कोर्स प्रदान करते हैं।</p>
      </div>
    </div>
  </div>
<footer class="footer-container">
<div class="row">
      <div class="col-md-2"></div>
        <div class="col-md-4 p-mt-8">
          <span>Copyright @</span><a href="www.learnmorepro.com"> Learn More Pro.</a><span> All Rights Reserved </span>
        </div>
        <div class="col-md-4 p-mt-8">
          <span> Designed & Developed By </span><a href="https://neurosparkworks.com/">Neuro Spark Works</a>
        </div>
        <!--div class="p-col-2">
            <a target="_blank">Terms & Condition</a>
        </div>
        <div class="p-col-2">
            <a>Contact Us</a>
        </div>
        <div class="p-col-2">
            <a>About Us</a>
        </div-->
        <div class="col-md-2"></div>
        
    </div>
</footer>
